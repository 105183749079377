﻿"use strict";

///Constants representing the parameter names
const GLOSSARY_ID_PARAMNAME = "glossaryId";
const SITE_PARAMNAME = "siteCode";
const LANGUAGE_PARAMNAME = "languageCode";
const NPDI_PROJECT_CODE_PARAMNAME = "NestleProjectNumber";
const IS_HARMONIZATION_PARAMNAME = "Harmonization";
const TEMPLATE_NAME = "TemplateName";

///Constants representing API method urls
/// Defect Material Setup
const API_METHOD_ADD_DEFECT_MATERIAL_SETUPS =
  "Defect/v1.0/DefectMaterialSetup/AddDefectMaterialSetups";
const API_METHOD_DELETE_DEFECT_MATERIAL_SETUPS =
  "Defect/v1.0/DefectMaterialSetup/DeleteDefectMaterialSetups";
const API_METHOD_REMOVE_DEFECTS_FROM_SELECTION =
  "Defect/v1.0/DefectMaterialSetup/RemoveDefectsFromSelection";
const API_METHOD_POST_MASS_UPLOAD_FILE_GENERATION =
  "Defect/v1.0/DefectMaterialSetup/MassUploadFileGeneration";
const API_METHOD_POST_MASS_UPLOAD_MATERIAL_DEFECT_MAPPING =
  "Defect/v1.0/DefectMaterialSetup/MassUploadMaterialDefectMapping";
/// Material
const API_METHOD_DEFECT_MATERIALS_SEARCH =
  "Defect/v1.0/DefectMaterialSetup/SearchMaterial";
/// Defect
const API_METHOD_GET_DEFECTS_BY_MATERIAL_CODES_V11 =
  "Defect/v1.1/Defect/GetDefectsByMaterialCodes";
/// Defect Level
const API_METHOD_GET_DEFECT_LEVELS = "Defect/v1.0/DefectLevel/GetDefectLevels";
/// Defect Category
const API_METHOD_GET_DEFECT_CATEGORIES =
  "Defect/v1.0/DefectCategory/GetDefectCategories";
/// Defect Type
const API_METHOD_GET_DEFECT_TYPES = "Defect/v1.0/DefectType/GetDefectTypes";
/// Sensory Attributes
const API_METHOD_ADD_SENSORY_ATTRIBUTE =
  "Sensory/v1.0/SensoryAttribute/AddSensoryAttribute";

const API_METHOD_PATCH_SENSORY_ATTRIBUTE = "odata/SensoryAttributes";
const API_METHOD_SENSORY_CHARACTERISTIC_TRANSLATIONS =
  "/odata/SensoryCharacteristicTranslations";
const API_METHOD_GET_PRODUCT_CATEGORY_CODE_BY_GLOSSARY_ID =
  "Sensory/v1.0/SensoryAttribute/GetProductCategoryCodeByGlossaryId";
const API_METHOD_SEARCH_SENSORY_ATTRIBUTE_SUGGESTIONS =
  "Sensory/v1.0/SensoryAttribute/SearchSensoryAttributeSuggestions";
const API_MEHTOD_GET_DEFAULT_SENSORY_ATTRIBUTE_DTO =
  "Sensory/v1.0/SensoryAttribute/GetDefaultDTO";
const API_METHOD_GET_SENSORY_ATTRIBUTE_BY_ID =
  "Sensory/v1.0/SensoryAttribute/GetSensoryAttributeById";
const API_METHOD_SENSORY_GLOSSARY_HARMONIZATION_RULES_TEMPLATES =
  "odata/SensoryGlossaryHarmonizationRulesTemplates";
const API_METHOD_NPDI_KEY_PROJECTS = "odata/NpdiKeyProjects";
const API_METHOD_NPDI_PROJECTS = "odata/NpdiProjects";
const API_METHOD_HARMONIZATION_RULES_TEMPLATES =
  "odata/HarmonizationRulesTemplates";
const API_METHOD_XMDR_PRODUCT_CATEGORIES = "odata/XmdrProductCategories";
const API_METHOD_SENSORY_GLOSSARIES = "odata/SensoryGlossaries";
const API_METHOD_XMDR_AD_USERS = "odata/XmdrAdUsers";
const API_METHOD_NOTIFICATION_OPTIONS = "odata/NotificationOptions";
const API_METHOD_SENSORY_GLOSSARY_OWNERS = "odata/SensoryGlossaryOwners";
const API_METHOD_SENSORY_FEATURES = "odata/SensoryFeatures";
const API_METHOD_CONSUMER_GLOSSARIES = "odata/ConsumerGlossaries";
/// ISO Setting
const API_METHOD_GET_ISO_SETTING_BY_SETTING_ID =
  "Settings/v1.0/ISOSetting/GetISOSettingBySettingId";
/// Assembly version
const API_METHOD_GET_ASSEMBLY_VERSION = "oData/GetAssemblyVersion";
/// AD User
const API_METHOD_GET_ADUSERS_BY_FILTER =
  "Settings/v1.0/ADUser/GetADUsersByFilter";
// ISO6040
// Yearly plan - Derogation form
const API_METHOD_SET_DEROGATION_FORM =
  "ISO6040/v1.0/DerogationForm/SetDerogationForm";
const API_METHOD_DOWNLOAD_DEROGATION_FORM =
  "ISO6040/v1.0/DerogationForm/DownloadCurrentDerogationForm";

function hasValue(jsObject) {
  return jsObject !== null && jsObject !== undefined;
}

function editMeta(object, metaEditFunc) {
  if (!object.meta) {
    object.meta = {};
  }

  metaEditFunc(object.meta);

  return object;
}

function getServiceUrl() {
  ///<summary>Gets the environment related service URL.</summary>
  return $("#service_url").val().toString();
}

function getPIDPServiceURL() {
  ///<summary>Gets the environment related Product Id Provider service URL.</summary>
  return $("#pidp_url").val();
}

function getQueryString() {
  /// <summary>Gets the query string part of the URL.</summary>
  /// <returns type="string">Query string</returns>
  return window.location.search.substring(1);
}

function getParameters(queryString) {
  /// <summary>Returns a "key-value" pair "list" representing the parameters.</summary>
  /// <param name="queryString" type="string">Representing a URL query string.</param>
  /// <returns>"Associative" array with the keys of parameter names paired with the respective values.</returns>
  if (!queryString) queryString = getQueryString();

  var rParamList = {};

  var params = queryString.split("&");
  for (let i = 0; i < params.length; i++) {
    var pair = params[i].split("=");

    var parameterName = createCreationWindowParameterName(
      decodeURIComponent(pair[0])
    );

    if (parameterName) {
      rParamList[parameterName] = decodeURIComponent(pair[1]);
    }
  }

  return rParamList;
}

function createCreationWindowParameterName(currentParamName) {
  ///<summery>Creates parameter name used by creation window.</summery>
  ///<param name="currentParamName" type="string">Represents a query-string parameter name.</param>
  /// <returns>Returns relevant parameter name.</returns>

  if (currentParamName.toLowerCase() === GLOSSARY_ID_PARAMNAME.toLowerCase()) {
    return GLOSSARY_ID_PARAMNAME;
  } else if (currentParamName.toLowerCase() === SITE_PARAMNAME.toLowerCase()) {
    return SITE_PARAMNAME;
  } else if (
    currentParamName.toLowerCase() === LANGUAGE_PARAMNAME.toLowerCase()
  ) {
    return LANGUAGE_PARAMNAME;
  } else if (
    currentParamName.toLowerCase() === NPDI_PROJECT_CODE_PARAMNAME.toLowerCase()
  ) {
    return NPDI_PROJECT_CODE_PARAMNAME;
  } else if (
    currentParamName.toLowerCase() === IS_HARMONIZATION_PARAMNAME.toLowerCase()
  ) {
    return IS_HARMONIZATION_PARAMNAME;
  }
  return undefined;
}

function ErrorHandler(message) {
  alert(message);
  //Close the window if there is no product category for the glossary.
  //To close without prompting the user.
  window.open("", "_self", "");
  window.close();
}

function isNullOrUndefined(obj) {
  return obj === undefined || obj === null;
}

const System = {
  argNullCheck: function (arg, argName) {
    if (isNullOrUndefined(arg))
      throw new Error(`Argument null or undefined: '${argName}'!`);
  },

  checkObjectSignature: function (object, interfaceObj) {
    this.argNullCheck(object, "object");
    this.argNullCheck(interfaceObj, "interface");

    let objectKeys = Object.keys(object);
    let interfaceFunctionNames = Object.keys(interfaceObj.Functions);
    let interfacePropertyNames = Object.keys(interfaceObj.Properties);

    interfaceFunctionNames.forEach(function (interfaceKey) {
      let foundOnObject = objectKeys.some(function (objectKey) {
        return interfaceKey === objectKey;
      });

      if (!foundOnObject)
        throw new Error(
          `Interface function member '${interfaceKey}' not found on object!`
        );
    });

    interfacePropertyNames.forEach(function (interfaceKey) {
      let foundOnObject = objectKeys.some(function (objectKey) {
        return interfaceKey === objectKey;
      });

      if (!foundOnObject)
        throw new Error(
          `Interface property member '${interfaceKey}' not found on object!`
        );
    });
  },

  implements: function (object, interfaceObj) {
    this.argNullCheck(object, "object");
    this.argNullCheck(interfaceObj, "interface");

    let objectKeys = Object.keys(object);
    let interfaceFunctionNames = Object.keys(interfaceObj.Functions);
    let interfacePropertyNames = Object.keys(interfaceObj.Properties);

    let anyFunctionNotFound = interfaceFunctionNames.some(function (
      interfaceKey
    ) {
      let foundOnObject = objectKeys.some(function (objectKey) {
        return interfaceKey === objectKey;
      });

      return !foundOnObject;
    });

    let anyPropertyNotFound = interfacePropertyNames.some(function (
      interfaceKey
    ) {
      let foundOnObject = objectKeys.some(function (objectKey) {
        return interfaceKey === objectKey;
      });

      return !foundOnObject;
    });

    return !anyFunctionNotFound && !anyPropertyNotFound;
  },
};

function getTime() {
  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;

  return time;
}

function EventHandler() {
  this.listeners = [];

  this.subscribe = function (listener, eventDescription) {
    if (!listener) throw new Error("Argument null or undefined: 'listener'!");

    // if (!eventDescription)
    //   throw new Error("Argument null or undefined: 'eventDescription'!");

    let listenerObj = {
      Description: eventDescription,
      Callback: listener,
    };

    this.listeners.unshift(listenerObj);

    // for chaining subscriptions
    return this;
  };

  this.fire = function () {
    this.listeners.forEach(function (listenerObj) {
      log(`Event fired [${listenerObj.Description}]`);
      listenerObj.Callback();
    });
  };
}

function SimpleDelta(entity) {
  if (!entity) throw new Error("Argument null or undefined: 'entity'!");

  this.Entity = entity;
  this.ChangedProperties = [];

  // mark all entity properties as changed property
  for (var propertyName in entity) {
    this.ChangedProperties.push(propertyName);
  }
}

function log(text) {
  console.log(`[${getTime()}] ${text}`);
}

function notnull(object, objectName) {
  let objectNameMessage = objectName ? `: '${objectName}'` : "";

  if (isNullOrUndefined(object))
    throw new Error(`Object null or undefined${objectNameMessage}!`);

  return object;
}
